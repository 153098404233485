import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Fade } from "react-reveal"
import GalleryModal from "../components/gallerymodal"
import { useQueryParam, StringParam } from "use-query-params"
import SEO from "../components/seo"
import Quote from "src/components/Quote"

interface Props {
  data: GatsbyTypes.GalleryQuery
}

const GalleryPage = ({ data }: Props) => {
  const [height, setHeight] = useState<number>(0)
  const [currIdParam, setCurrIdParam] = useQueryParam("img", StringParam)
  const [currId, setCurrId] = useState<string | null | undefined>(currIdParam)

  useEffect(() => {
    setCurrIdParam(currId)
  }, [currId])

  useEffect(() => {
    if (currId) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }

    return () => {
      document.documentElement.style.overflowY = "scroll"
    }
  }, [currId])

  const next = () =>
    setCurrId(id => {
      const currIdx = images.findIndex(
        image => image != null && image.id === id
      )
      if (currIdx !== -1) {
        const nextIdx = (currIdx + 1) % images.length
        const nextImg = images[nextIdx]
        if (nextImg != null) {
          return nextImg.id
        }
      }
    })

  const prev = () =>
    setCurrId(id => {
      const currIdx = images.findIndex(
        image => image != null && image.id === id
      )
      if (currIdx !== -1) {
        const prevIdx = (currIdx - 1 + images.length) % images.length
        const prevImg = images[prevIdx]
        if (prevImg != null) return prevImg.id
      }
    })

  const downHandler = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      setCurrId(undefined)
    }
    if (key === "ArrowRight") {
      next()
    }
    if (key === "ArrowLeft") {
      prev()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, [])

  const images = data.contentfulGallery?.images ?? []
  const currImg = images.find(image => image != null && image.id === currId)

  const imgOnClick = (id: string) => {
    setCurrId(id)
  }

  const imgCol = (left: boolean) => {
    if (images == null) {
      return null
    }
    return images
      .filter((_, i) => {
        return i % 2 == (left ? 0 : 1)
      })
      .map((image, i) => {
        if (image == null || image.gatsbyImageData == null) {
          return null
        }
        return (
          <div
            style={{
              padding: `2vw`,
              boxSizing: `border-box`,
            }}
            key={i * 2 + (left ? 0 : 1)}
          >
            <div
              onClick={() => {
                image.id != null ? imgOnClick(image.id) : null
              }}
              style={{
                cursor: `zoom-in`,
              }}
              tabIndex={0}
            >
              <Fade bottom>
                <GatsbyImage image={image.gatsbyImageData} />
              </Fade>
            </div>
          </div>
        )
      })
  }

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title="Gallery" />
      {currId != null && (
        <GalleryModal
          key={currId}
          currImg={currImg}
          close={() => {
            setCurrId(undefined)
          }}
          prev={prev}
          next={next}
        />
      )}
      <main
        style={{
          marginTop: `${height}px`,
          padding: `6.6vmax 4vw 4vw`,
          boxSizing: "border-box",
          textAlign: `center`,
        }}
      >
        <h1 style={{ marginBottom: 17 }}>Gallery</h1>
        {data.contentfulQuote != null && <Quote data={data.contentfulQuote} />}
        <div
          style={{
            textAlign: `center`,
            display: `flex`,
            // flexWrap: `wrap`,
            margin: `-2vw`,
          }}
        >
          <div style={{ width: `50%` }}>{imgCol(true)}</div>
          <div style={{ width: `50%` }}>{imgCol(false)}</div>
          {/* {images.map(image => (
            <div
              style={{
                width: `50%`,
                padding: `2vw`,
                boxSizing: `border-box`,
                display: `inline-block`,
              }}
            >
              <Fade bottom>
                <Img fluid={image} />
              </Fade>
            </div>
          ))} */}
        </div>
      </main>
    </Layout>
  )
}

export default GalleryPage

export const pageQuery = graphql`
  query Gallery {
    contentfulGallery(title: { eq: "Gallery" }) {
      images {
        id
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    contentfulQuote(pageId: { eq: "gallery" }) {
      ...Quote
    }
  }
`
