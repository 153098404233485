import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { BsChevronLeft, BsChevronRight, BsX } from "react-icons/bs"

interface Props {
  close: () => void
  imgId?: string
  prev?: () => void
  next?: () => void
  currImg: NonNullable<
    NonNullable<GatsbyTypes.GalleryQuery["contentfulGallery"]>["images"]
  >[0]
}

const GalleryModal = ({ imgId, close, prev, next, currImg }: Props) => {
  return (
    <div
      onClick={close}
      style={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `fixed`,
        top: 0,
        height: `100vh`,
        width: `100vw`,
        zIndex: 10,
        backgroundColor: `rgba(246, 245, 234, 0.9)`,
      }}
    >
      <div
        style={{
          position: `fixed`,
          top: `1em`,
          right: `1em`,
        }}
      >
        <BsX size={40} color="black" style={{ cursor: "pointer" }} />
      </div>
      {prev != null && (
        <a
          style={{ height: `70vh`, display: `flex`, alignItems: `center` }}
          onClick={e => {
            e.stopPropagation()
            prev()
          }}
        >
          <div>
            <BsChevronLeft
              size={40}
              color="black"
              style={{ cursor: "pointer" }}
            />
          </div>
        </a>
      )}
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
          height: `80vh`,
          width: `80vw`,
        }}
        onClick={e => e.stopPropagation()}
      >
        {currImg != null && (
          <GatsbyImage
            image={currImg.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            style={{ width: `100%` }}
            alt={""}
          />
        )}
        {/* <div
            style={{
              width: `80%`,
              marginTop: 17,
              padding: `1em`,
              backgroundColor: `rgba(0, 0, 0, 0.8)`,
            }}
          >
            <p
              style={{
                color: `white`,
                fontSize: 24,
                textAlign: `center`,
              }}
            >
              {captionText}
            </p>
          </div> */}
      </div>
      {next != null && (
        <a
          style={{ height: `70vh`, display: `flex`, alignItems: `center` }}
          onClick={e => {
            e.stopPropagation()
            next()
          }}
        >
          <div>
            <BsChevronRight
              size={40}
              color="black"
              style={{ cursor: "pointer" }}
            />
          </div>
        </a>
      )}
    </div>
  )
}

export default GalleryModal
